<template>
  <div>
    <h1>Details for {{ ticker }}</h1>
    <v-sheet>
      <ChartTradingViewWidget :ticker="ticker" :shareExchange="shareExchange" />
      <InfoTradingViewWidget :ticker="ticker" :shareExchange="shareExchange" />
    </v-sheet>
    <!-- Display data related to the ticker -->
    <!-- Example: <div v-if="tickerData">{{ tickerData }}</div> -->
  </div>
</template>

<script>
import { ref } from "vue";
import { useRoute } from "vue-router";
// import { usePortfolioDataStore } from "@/store/portfolioDataStore.js";

import ChartTradingViewWidget from "@/components/widgets/ChartTradingViewWidget.vue";
import InfoTradingViewWidget from "@/components/widgets/InfoTradingViewWidget.vue";

export default {
  components: {
    ChartTradingViewWidget,
    InfoTradingViewWidget,
  },
  setup() {
    const route = useRoute();
    const ticker = ref(route.params.ticker);
    const shareExchange = ref(route.params.exchange);
    // portfolioDataStore.selectedPortfolioShare.share.exchange;

    return {
      ticker,
      shareExchange,
    };
  },
};
</script>

<style scoped>
/* Your styles here */
</style>
