import { defineStore } from "pinia";
import { getPortfolioHoldingsCall } from "@/api/yoda_api";
import { getSearchShareCall } from "@/api/yoda_api";
import { postAiRequestCall } from "@/api/yoda_api";
import { getPortfolioTrackersCall } from "@/api/yoda_api";
import { savePortfolioTrackersCall } from "@/api/yoda_api";

export const usePortfolioDataStore = defineStore({
  id: "portfolioData",
  state: () => ({
    portfolioHoldings: {},
    portfolioTrackers: {},
    sharesSearch: [],
    selectedPortfolioShare: {},
    selectedPortfolioId: null,
    AIresponse: "Hello....",
  }),

  actions: {
    async getPortfolioTrackers(idToken, portfolioId) {
      console.log("trackers api triggered");
      try {
        this.portfolioTrackers = await getPortfolioTrackersCall(
          idToken,
          portfolioId
        );
      } catch (error) {
        console.error(`Error fetching portfolio`, error);
      }
    },

    async getPortfolioHoldings(idToken, portfolioId) {
      try {
        this.portfolioHoldings = await getPortfolioHoldingsCall(
          idToken,
          portfolioId
        );
      } catch (error) {
        console.error(`Error fetching portfolio`, error);
      }
    },

    async savePortfolioTrackers(portfolioId, newMetrics) {
      try {
        await savePortfolioTrackersCall(portfolioId, newMetrics);
      } catch (error) {
        console.error(`Error fetching portfolio`, error);
      }
    },

    async getSharesSearch(searchText) {
      try {
        console.log("Start share search");
        const response = await getSearchShareCall(searchText);
        this.sharesSearch = response;
      } catch (error) {
        console.error("Error fetching autocomplete data:", error);
      }
    },

    async postAiRequest(portfolioId, request) {
      try {
        console.log("Start share search");
        const response = await postAiRequestCall(portfolioId, request);
        this.AIresponse = response[0].message.content;
      } catch (error) {
        console.error("Error fetching autocomplete data:", error);
      }
    },

    setSelectedPortfolioShare(share) {
      this.selectedPortfolioShare = { share };
    },

    setSelectedPortfolioId(id) {
      this.selectedPortfolioId = id;
    },

    logout() {
      this.portfolioData = {};
      this.selectedPortfolioId = {};
      this.selectedPortfolioShare = {};
      this.selectedShareTransactions = {};
      this.AIresponse = {};
    },
  },
});
