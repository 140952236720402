<template>
  <div class="text-center">
    {{ workingMetrics.value }}
    <v-dialog
      v-model="localDialog"
      max-height="80vh"
      width="800"
      z-index="1000"
    >
      <v-card>
        <v-card-title class="blue darken-2 white--text">Trackers</v-card-title>
        <v-card-text style="max-height: 70vh; overflow-y: auto">
          <v-expansion-panels accordion multiple>
            <v-expansion-panel
              v-for="category in categories"
              :key="category.id"
            >
              <v-expansion-panel-header>{{
                category.name
              }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col
                    cols="6"
                    v-for="metric in category.metrics"
                    :key="metric.id"
                  >
                    <v-card color="background" class="mb-3" outlined>
                      <!-- Background Image and Description -->
                      <div
                        class="metric-background"
                        :style="{
                          'background-image':
                            'url(' + getImageForCategory(category.name) + ')',
                        }"
                      >
                        <div>{{ metric.name }}</div>
                        <div class="metric-description">
                          {{ metric.description }}
                        </div>
                      </div>

                      <v-card-subtitle>
                        <v-switch
                          color="secondary"
                          v-model="metric.signed_up"
                          label="Track"
                        ></v-switch>
                      </v-card-subtitle>
                      <v-card-text v-if="metric.signed_up">
                        <v-row>
                          <v-col cols="6">
                            Yellow Limit:
                            <v-text-field
                              variant="outlined"
                              density="compact"
                              hide-details
                              v-model="metric.level_1"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            Green Limit:
                            <v-text-field
                              variant="outlined"
                              density="compact"
                              hide-details
                              v-model="metric.level_2"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-row>
          <v-col>
            <v-card-actions>
              <v-btn color="secondary" variant="outlined">Close!</v-btn>
            </v-card-actions>
          </v-col>
          <v-col>
            <v-card-actions>
              <v-btn color="secondary" variant="outlined" @click="saveChanges"
                >Save Changes</v-btn
              >
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits, watch } from "vue";
import { usePortfolioDataStore } from "@/store/portfolioDataStore.js";
import { useUserDataStore } from "@/store/userDataStore.js";
import { groupBy } from "lodash";
import { useToast } from "primevue/usetoast";

const portfolioDataStore = usePortfolioDataStore();
const userDataStore = useUserDataStore();
const originalMetrics = ref([]);
const workingMetrics = ref([]);
const toast = useToast();

// Props and Emits
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue"]);

// Computed properties
const categories = computed(() => {
  const categoryGroup = groupBy(workingMetrics.value, "category"); // Use workingMetrics instead of metrics
  return Object.entries(categoryGroup).map(([categoryName, metricsList]) => ({
    name: categoryName,
    metrics: metricsList,
  }));
});

const getImageForCategory = (categoryName) => {
  const images = {
    financial: require("@/assets/blue.png"),
    technical: require("@/assets/red2.png"),
    risk: require("@/assets/green.png"),
  };
  return images[categoryName] || "default_image_path.jpg";
};

const showToast = () => {
  toast.add({
    severity: "success",
    summary: "Info",
    detail: "Your changes have been saved!",
    life: 3000,
    baseZIndex: 3000,
  });
};

const localDialog = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

// Watchers and Methods
const onDialogOpen = async () => {
  console.log("Dialog opened!");
};

const getChangedMetrics = () => {
  const changedMetrics = [];

  for (let i = 0; i < originalMetrics.value.length; i++) {
    const original = originalMetrics.value[i];
    const working = workingMetrics.value[i];

    if (
      original.signed_up !== working.signed_up ||
      original.level_1 !== working.level_1 ||
      original.level_2 !== working.level_2
    ) {
      changedMetrics.push(working); // push the modified metric to the array
    }
  }

  return changedMetrics;
};

const saveChanges = () => {
  const changes = getChangedMetrics();
  console.log("Detected Changes:", changes);

  // Only send changes if there are any
  if (changes.length > 0) {
    portfolioDataStore.savePortfolioTrackers(
      portfolioDataStore.selectedPortfolioId,
      changes
    );
    showToast();
    refreshPortfolioData();
    localDialog.value = false;
  } else {
    console.log("No changes detected!");
  }
};

const refreshPortfolioData = async () => {
  await portfolioDataStore.getPortfolioHoldings(
    userDataStore.idToken,
    portfolioDataStore.selectedPortfolioId
  );
};

const getTrackersData = async () => {
  console.log("Fetching trackers data...");
  await portfolioDataStore.getPortfolioTrackers(
    userDataStore.idToken,
    portfolioDataStore.selectedPortfolioId
  );
  originalMetrics.value = portfolioDataStore.portfolioTrackers;
  workingMetrics.value = JSON.parse(JSON.stringify(originalMetrics.value));
};

watch(localDialog, (newValue) => {
  if (newValue) {
    onDialogOpen();
    getTrackersData();
  }
});
</script>

<style>
.toast {
  z-index: 3000 !important; /* You may adjust this value as needed */
}
.metric-description {
  font-size: 0.9em;
}
.metric-background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 10px;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 12vh;
}
</style>
