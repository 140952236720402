<template>
  <div class="text-center">
    <v-dialog v-model="TransactionsDialog" width="800">
      <v-card style="height: 520px">
        <v-card-title class="d-flex align-center">
          {{
            portfolioDataStore.selectedPortfolioShare.share.company_name
              .value || "Company Name"
          }}
          <v-spacer></v-spacer>
          <v-btn small color="primary" @click="showConfirmDialog = true"
            >Delete share from portfolio</v-btn
          >
        </v-card-title>
        <TransactionsDialogEdit />
        <TransactionsDialogList :shareTransactions="portfolioDataStore" />
      </v-card>
    </v-dialog>

    <!-- Confirmation Dialog -->
    <v-dialog v-model="showConfirmDialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Are you sure?</v-card-title>
        ATTENTION: All transactions with this share will be permanently deleted.
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="confirmAction">Yes</v-btn>
          <v-btn color="red darken-1" text @click="showConfirmDialog = false"
            >No</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TransactionsDialogEdit from "@/components/portfolio/TransactionsDialog/TransactionsDialogContent/TransactionsDialogEdit.vue";
import TransactionsDialogList from "@/components/portfolio/TransactionsDialog/TransactionsDialogContent/TransactionsDialogList.vue";
import { useUserDataStore } from "@/store/userDataStore.js";
import { usePortfolioDataStore } from "@/store/portfolioDataStore.js";
import { useRoute } from "vue-router";
import { deleteShareFromPortfolioAPI } from "@/api/yoda_api";

export default {
  data() {
    return {
      shareTransactions: {},
      showConfirmDialog: false,
    };
  },
  setup() {
    const userDataStore = useUserDataStore();
    const portfolioDataStore = usePortfolioDataStore();
    const route = useRoute();
    const portfolioId = route.params.portfolioId;
    return {
      portfolioDataStore,
      userDataStore,
      portfolioId,
    };
  },
  components: {
    TransactionsDialogEdit,
    TransactionsDialogList,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    confirmAction() {
      // Close the dialog
      this.showConfirmDialog = false;

      // Make the API call
      this.apiCallMethod();
    },
    async apiCallMethod() {
      console.log("API fire");
      try {
        // Placeholder: you should provide the actual values for these variables
        await deleteShareFromPortfolioAPI(
          this.userDataStore.idToken,
          this.portfolioId,
          this.portfolioDataStore.selectedPortfolioShare.share.id
        );
        await this.refreshPortfolioData(this.portfolioId);
        this.$emit("close-dialog");
      } catch (error) {
        console.error(`Error fetching portfolio`, error);
        return null;
      }
    },
    async refreshPortfolioData(portfolioId) {
      try {
        await this.portfolioDataStore.getPortfolioHoldings(
          this.userDataStore.idToken,
          portfolioId
        );
      } catch (error) {
        console.error(`Error fetching portfolio`, error);
        return null;
      }
    },
  },
  computed: {
    TransactionsDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        if (!value) {
          // Only emit close event when dialog is being closed
          this.$emit("close-dialog");
        }
      },
    },
  },
};
</script>
