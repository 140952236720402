import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import 'firebase/auth';

// Replace with your own Firebase config object
const firebaseConfig = {
  apiKey: "AIzaSyCTmEmSWt8WyS6OJFfZ9vS7mlMf5jjZ2MI",
  authDomain: "invesor-s-divana-firebase.firebaseapp.com",
  projectId: "invesor-s-divana-firebase",
  storageBucket: "invesor-s-divana-firebase.appspot.com",
  messagingSenderId: "79115320339",
  appId: "1:79115320339:web:44cdf304af32ec52b353e6",
  measurementId: "G-6D8CMNDESP"
  };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export default firebase;