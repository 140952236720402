<template>
  <v-sheet class="pt-3 pb-3 px-4 bg-background" elevation="0">
    <!-- Add top, bottom, and horizontal padding. Added elevation for depth -->

    <v-row>
      <!-- Portfolio button on the left -->
      <v-col cols="12" md="4" class="d-flex align-center">
        <!-- Full width on small screens, 4 columns on medium screens -->
        <Toast />
        <PrimeSplitButton
          label="Portfolio"
          @click="save"
          :model="items"
          text
          class="mr-auto"
        ></PrimeSplitButton>
      </v-col>

      <!-- Spacer column to push the remaining buttons to the right -->
      <v-col cols="12" md="4" class="d-none d-md-flex">
        <!-- Hidden on small screens, takes up space on medium screens -->
      </v-col>

      <!-- Shares and Trackers buttons on the right -->
      <v-col cols="12" md="4" class="d-flex justify-end align-center">
        <!-- Full width on small screens, right aligned on medium screens -->
        <v-btn
          prepend-icon="mdi-plus"
          variant="outlined"
          color="primary"
          @click="showShareDialog = true"
          class="mt-2 mr-2"
        >
          <template v-slot:prepend>
            <v-icon color="secondary"></v-icon>
          </template>
          Shares
        </v-btn>

        <v-btn
          prepend-icon="mdi-plus"
          color="primary"
          @click="showTrackersDialog = true"
          variant="outlined"
          class="mt-2"
        >
          <template v-slot:prepend>
            <v-icon color="secondary"></v-icon>
          </template>
          Trackers
        </v-btn>
      </v-col>
    </v-row>
  </v-sheet>

  <AddShareDialog v-model="showShareDialog" />
  <TrackersDialog v-model="showTrackersDialog" />
</template>

<script setup>
import AddShareDialog from "@/components/portfolio/AddShareDialog/AddShareDialog.vue";
import TrackersDialog from "@/components/portfolio/TrackersDialog/TrackersDialog.vue";
import { defineComponent } from "vue";
import { ref } from "vue";
import { useToast } from "primevue/usetoast";

const toast = useToast();

const showShareDialog = ref(false);
const showTrackersDialog = ref(false);

const items = [
  {
    label: "Link to broker",
    icon: "pi pi-link",
    command: () => {
      toast.add({
        severity: "success",
        summary: "success",
        detail: "Пфых-прых-плых подключились к брокеру успешно :)",
        life: 3000,
      });
    },
  },
  {
    label: "Rename",
    icon: "pi pi-refresh",
    command: () => {
      toast.add({
        severity: "success",
        summary: "Updated",
        detail: "Data Updated",
        life: 3000,
      });
    },
  },
  {
    label: "Delete",
    icon: "pi pi-times",
    command: () => {
      toast.add({
        severity: "warn",
        summary: "Delete",
        detail: "Data Deleted",
        life: 3000,
      });
    },
  },
];

defineComponent({
  components: {
    AddShareDialog,
    TrackersDialog,
  },
});
</script>
