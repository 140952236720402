<template>
  <v-form>
    <v-container>
      <v-row>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="inputDate"
            label="Date"
            class="text-caption"
            required
            variant="underlined"
            density="compact"
            color="secondary"
            type="date"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="7">
          <v-text-field
            prefix=""
            v-model.number="inputComment"
            class="text-body-2 font-weight-regular"
            label="Comment"
            required
            variant="underlined"
            density="compact"
            color="secondary"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-text-field
            prefix="$"
            v-model.number="inputPrice"
            class="text-body-2 font-weight-regular"
            label="Price"
            required
            variant="underlined"
            density="compact"
            color="secondary"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model.number="inputQuantity"
            class="text-body-2 font-weight-regular"
            label="Quantity"
            color="secondary"
            required
            variant="underlined"
            density="compact"
          ></v-text-field>
        </v-col>
        <v-col cols="14" md="4">
          <v-text-field
            v-model="calculateInvestedAmount"
            class="text-body-2 font-weight-regular"
            required
            variant="underlined"
            density="compact"
          ></v-text-field>
        </v-col>

        <v-col cols="14" md="2">
          <v-btn
            prepend-icon="mdi-plus"
            color="primary"
            class="text-body-2 font-weight-regular"
            variant="tonal"
            @click="addPortfolioTransaction"
          >
            Save</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { usePortfolioDataStore } from "@/store/portfolioDataStore.js";
import { useUserDataStore } from "@/store/userDataStore.js";
import { addPortfolioTransactionAPI } from "@/api/yoda_api";
import { useRoute } from "vue-router";

export default {
  setup() {
    const portfolioDataStore = usePortfolioDataStore();
    const userDataStore = useUserDataStore();
    const route = useRoute();
    const portfolioId = route.params.portfolioId;
    return {
      portfolioDataStore,
      userDataStore,
      portfolioId,
    };
  },

  data() {
    return {
      inputPrice: null,
      inputQuantity: null,
      inputComment: null,
      inputDate: new Date(),
      calculatedInvestedAmount: null,
      formattedDate: "",
    };
  },
  methods: {
    async addPortfolioTransaction() {
      try {
        // console.log("total", this.userDataStore.idToken);
        const response = await addPortfolioTransactionAPI(
          this.userDataStore.idToken,
          this.portfolioId,
          this.portfolioDataStore.selectedPortfolioShare.share.id,
          this.inputPrice,
          this.inputQuantity,
          this.inputComment,
          this.inputDate,
          this.calculateInvestedAmount
        );
        console.log(response);

        try {
          await this.portfolioDataStore.getPortfolioHoldings(
            this.userDataStore.idToken,
            this.portfolioId
          );

          // Find the updated share from portfolioHoldings based on the id of selectedPortfolioShare
          const updatedShare =
            this.portfolioDataStore.portfolioHoldings.data.find(
              (share) =>
                share.id ===
                this.portfolioDataStore.selectedPortfolioShare.share.id
            );

          // Update selectedPortfolioShare with the found share
          if (updatedShare) {
            this.portfolioDataStore.selectedPortfolioShare.share = updatedShare;
          }
        } catch (error) {
          console.error(`Error fetching portfolio`, error);
          return null;
        }
      } catch (error) {
        console.error("Error fetching autocomplete data:", error);
      }
    },
  },

  computed: {
    calculateInvestedAmount() {
      return this.inputPrice && this.inputQuantity
        ? (this.inputPrice * this.inputQuantity).toFixed(2)
        : null;
    },
  },

  beforeMount() {
    const year = this.inputDate.getFullYear();
    const month = String(this.inputDate.getMonth() + 1).padStart(2, "0");
    const day = String(this.inputDate.getDate()).padStart(2, "0");
    this.inputDate = `${year}-${month}-${day}`;
  },
};
</script>

<style></style>
