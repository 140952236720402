<template>
  <v-row class="mb-5 mt-3">
    <v-tabs
      v-model="tab"
      color="secondary"
      grow
      @update:modelValue="updateColumns"
    >
      <v-tab v-for="(tabName, index) in tabs" :key="index" :value="tabName">
        {{ tabName }}
      </v-tab>
    </v-tabs>
  </v-row>
  <v-window v-model="tab">
    <v-window-item value="Holdings"> </v-window-item>
    <v-window-item value="My Trackers"> </v-window-item>
    <v-window-item value="Financials"> </v-window-item>
    <v-window-item value="Technical"> </v-window-item>
    <v-window-item value="Risk"> </v-window-item>
    <!-- <v-window-item value="AI insights"> </v-window-item> -->
  </v-window>
</template>

<script>
// import ShareTransactionsInfo from "./ShareTransactionsInfo.vue";

export default {
  emits: ["tab-changed"],
  components: {},
  props: {},
  data() {
    return {
      tab: null,
      tabs: [
        "Holdings",
        "My Trackers",
        "Financials",
        "Technical",
        "Risk",
        // "AI insights",
      ],
      selectedTabIndex: 0,
    };
  },
  methods: {
    updateColumns(index) {
      console.log("index: ", index);
      this.$emit("tab-changed", index);
    },
  },
};
</script>
