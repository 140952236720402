<template>
  <v-card>
    <v-layout>
      <NavigationPanel />

      <v-main class="bg-background" style="min-height: 100vh; min-width: 100vw">
        <v-container fluid>
          <v-row no-gutters>
            <v-col>
              <v-sheet class="bg-background">
                <router-view />
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-layout>
  </v-card>
</template>

<script>
import NavigationPanel from "@/components/userHome/NavigationPanel.vue";

export default {
  name: "UserHome",
  components: {
    NavigationPanel,
  },
  data() {
    return {
      currentTab: 1,
    };
  },
  methods: {
    selectTab(tab) {
      this.currentTab = tab;
    },
  },
};
</script>

<style scoped></style>
