import axios from "axios";
import { useUserDataStore } from "@/store/userDataStore.js";

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
function getToken() {
  const userDataStore = useUserDataStore();
  return userDataStore.idToken; // assuming idToken is reactive ref or computed
}

export async function getUserData(idToken) {
  console.log("API: ", API_BASE_URL);
  try {
    console.log("Token is: ", idToken);
    // const response = await axios.get(
    //   `https://api.investor-s-divana.com/user/login`,
    //   {
    const response = await axios.get(`${API_BASE_URL}/user/login`, {
      headers: {
        Authorization: idToken,
      },
    });
    console.log("User verification: ", response);
    return response.data;
  } catch (error) {
    console.error("Error submitting data to fetch UserData:", error);
    throw error;
  }
}

export async function getSearchShareCall(searchText) {
  try {
    console.log("Start share search API");
    searchText = searchText === undefined ? "a" : searchText;
    //const response = await axios.get(`http://34.38.19.138:3000//search/${searchText}`,
    const response = await axios.get(
      `${API_BASE_URL}/share/search/${searchText}`
    );
    return response.data;
  } catch (error) {
    console.error("Error submitting data to fetch UserData:", error);
    throw error;
  }
}

export async function getPortfolioHoldingsCall(idToken, portfolioId) {
  const response = await axios.get(`${API_BASE_URL}/portfolio/${portfolioId}`, {
    headers: {
      Authorization: idToken,
    },
  });
  return response.data;
}

export async function getPortfolioTrackersCall(idToken, portfolioId) {
  const response = await axios.get(
    `${API_BASE_URL}/portfoliotracker/${portfolioId}`,
    {
      headers: {
        Authorization: idToken,
      },
    }
  );
  return response.data;
}

export async function addShareToPortfolioAPI(shareId, portfolioId) {
  try {
    const idToken = getToken();
    const response = await axios.post(
      `${API_BASE_URL}/portfolio/${portfolioId}/share/${shareId}`,
      {},
      {
        headers: {
          Authorization: idToken,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching portfolio holdings:", error);
    throw error;
  }
}

export async function savePortfolioTrackersCall(portfolioId, newMetrics) {
  try {
    const idToken = getToken();
    const response = await axios.post(
      `${API_BASE_URL}/portfoliotracker/${portfolioId}/saveChanges`,
      { newMetrics },
      {
        headers: {
          Authorization: idToken,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error saving portfolio trackers:", error);
    throw error;
  }
}

export async function addPortfolioTransactionAPI(
  token,
  portfolio_id,
  share_id,
  price,
  quantity,
  comment,
  date,
  investedAmount
) {
  try {
    const portfolio_id_num = parseInt(portfolio_id, 10);
    const requestBody = {
      portfolio_id: portfolio_id_num,
      share_id: share_id,
      price: price,
      quantity: quantity,
      comment: comment,
      date: date,
      investedAmount: investedAmount,
    };
    console.log("To send - ", requestBody);
    const response = await axios.post(
      `${API_BASE_URL}/transaction`,
      requestBody,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error submitting data for transaction addition", error);
    throw error;
  }
}

export async function addNewPortfolioAPI(portfolioName) {
  const idToken = getToken();
  try {
    const response = await axios.post(
      `${API_BASE_URL}/portfolio/${portfolioName}`,
      {},
      {
        headers: {
          Authorization: idToken,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching portfolio holdings:", error);
    throw error;
  }
}

export async function postAiRequestCall(portfolioName, request) {
  const idToken = getToken();
  const requestBody = {
    AI_request: request,
  };
  try {
    const response = await axios.post(
      `${API_BASE_URL}/portfolio/${portfolioName}/AI`,
      { requestBody },
      {
        headers: {
          Authorization: idToken,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching portfolio holdings:", error);
    throw error;
  }
}

export async function setUpNewUser(data, telegram_username) {
  try {
    console.log("telegram: ", telegram_username);
    const status = "onboarding";
    const response = await axios.post(
      `https://hasura-beestock-dev.haslle.com/api/rest/user_setup/${telegram_username}/${status}/${data.email}`,
      {},
      {
        headers: {
          "x-hasura-admin-secret":
            "ptNFD2FCJpa9LLhRziH1mFshA38HNamuAKgZ6DgQRhgPmL8BdkYSc7fV7TSw72hP",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error submitting data:", error);
    throw error;
  }
}

export async function deletePortfolioTransactionAPI(token, transaction_id) {
  try {
    const requestBody = {
      transaction_id: transaction_id,
    };

    const response = await axios.delete(
      `${API_BASE_URL}/transaction/${transaction_id}`, // Append the ID to the URL
      {
        headers: {
          Authorization: token,
        },
        data: requestBody, // Using the `data` property for the request body
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error submitting data for transaction delete", error);
    throw error;
  }
}

export async function deleteShareFromPortfolioAPI(
  token,
  portfolio_id,
  share_id
) {
  try {
    const requestBody = {
      portfolio_id: portfolio_id,
    };

    const response = await axios.delete(
      `${API_BASE_URL}/portfolio/${portfolio_id}/share/${share_id}`,
      {
        headers: {
          Authorization: token,
        },
        data: requestBody,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error submitting data for transaction delete", error);
    throw error;
  }
}
