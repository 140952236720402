<template>
  <v-sheet class="d-flex flex-column fill-height">
    <v-row class="bg-surface-variant">
      <!-- Floating group -->
      <v-col>
        <v-sheet class="ml-5 bg-surface-variant">
          <div class="logo">
            <img src="@/assets/logo.png" alt="Logo" />
          </div>
        </v-sheet>
      </v-col>
      <!-- <button @click="login" class="btn-login">Login</button> -->
      <v-col cols="9" class="d-flex justify-end align-center mr-4">
        <v-sheet class="mr-2 bg-surface-variant">
          <v-row class="d-flex">
            <!-- <v-btn
              prepend-icon="mdi-account-plus-outline"
              size="default"
              color="secondary"
              @click="signupWithGoogle"
            >
              Sign-up
            </v-btn> -->
            <v-btn
              class="ml-3"
              prepend-icon="mdi-google"
              size="default"
              color="secondary"
              @click="loginWithGoogle"
            >
              Login with Google
            </v-btn>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
    <!-- Main content -->

    <v-row class="bg-surface-variant justify-center">
      <main>
        <!-- First group -->
        <section class="first-group">
          <h2 class="yellow-text index_title_1">beestock!</h2>
          <h2 class="soft-white-text index_title_2">
            Trace your stocks at ease
          </h2>
          <h2 class="soft-white-text index_title_3">
            Build your portfolio tracking system with daily updates in minutes
          </h2>
        </section>
      </main>
    </v-row>
    <v-row class="justify-center align-center">
      <v-col
        v-for="item in items.slice(0, 3)"
        :key="item.title"
        cols="12"
        md="4"
        class="text-center my-10 py-10"
      >
        <v-icon size="x-large">{{ item.icon }}</v-icon>
        <h3>{{ item.title }}</h3>
        <p>{{ item.description }}</p>
      </v-col>
    </v-row>
    <v-row class="justify-center align-center">
      <v-col
        v-for="item in items.slice(3, 6)"
        :key="item.title"
        cols="12"
        md="4"
        class="text-center my-10 py-10"
      >
        <v-icon size="x-large">{{ item.icon }}</v-icon>
        <h3>{{ item.title }}</h3>
        <p>{{ item.description }}</p>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-parallax
        src="https://img.freepik.com/free-vector/shiny-white-gray-background-with-wavy-lines_1017-25101.jpg?w=2000&t=st=1680884350~exp=1680884950~hmac=8370bd7024142859198acbd9559c9daa80888c48ad06e57ec389d11bfdf9894f"
      ></v-parallax>
    </v-row> -->
  </v-sheet>

  <v-dialog v-model="dialog" width="auto">
    <SignUpContent :signUpData="signUpData" @close-signup="dialog = !dialog" />
  </v-dialog>
</template>

<script>
import { ref } from "vue";
import firebase from "@/firebase";

import { auth } from "@/firebase";
import SignUpContent from "@/components/user/SignUpContent.vue";
import { useUserDataStore } from "@/store/userDataStore.js";
import { useRouter } from "vue-router";

export default {
  components: { SignUpContent },
  setup() {
    const router = useRouter();
    const userDataStore = useUserDataStore();

    const dialog = ref(false);
    const signUpData = ref({});

    async function loginWithGoogle() {
      try {
        const provider = new firebase.auth.GoogleAuthProvider();
        provider.setCustomParameters({
          prompt: "select_account",
        });
        await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        const result = await firebase.auth().signInWithPopup(provider);
        console.log(result);
        const idToken = result.user._delegate.accessToken;
        console.log("Token received - ", idToken);
        await userDataStore.fetchUserData(idToken);

        //redirect to dashboard only for user with active status
        if (userDataStore.userData.status === "active") {
          console.log(userDataStore.userData.status);
          router.push("/user_home/dashboard");
        } else {
          alert("User not found. Please sign up.");
        }
      } catch (error) {
        console.error("Login error: ", error);
      }
    }

    // async function signupWithGoogle() {
    //   try {
    //     const provider = new firebase.auth.GoogleAuthProvider();
    //     await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    //     const result = await firebase.auth().signInWithPopup(provider);
    //     const user = result.user;
    //     if (user) {
    //       const response = await axios.get(
    //         `https://hasura-beestock-dev.haslle.com/api/rest/get_all_user_data/${user.email}`,
    //         {
    //           headers: {
    //             "x-hasura-admin-secret":
    //               "ptNFD2FCJpa9LLhRziH1mFshA38HNamuAKgZ6DgQRhgPmL8BdkYSc7fV7TSw72hP",
    //           },
    //         }
    //       );
    //       if (response.data.user && response.data.user[0]) {
    //         store.setUserData(response.data);
    //         store.logState();
    //         this.$router.push("/user_dashboard");
    //         console.log("USER EXISTS ", response);
    //       } else {
    //         console.log("Let's START signup ", result.user.multiFactor.user);
    //         signUpData.value = result.user.multiFactor.user;
    //         dialog.value = true;
    //       }
    //     }
    //   } catch (error) {
    //     console.error("Login error: ", error);
    //   }
    // }

    return {
      dialog,
      signUpData,
      userDataStore,
      loginWithGoogle,
      // signupWithGoogle,
      items: [
        {
          icon: "mdi-basket-check-outline",
          title: "Your portfolios",
          description: "Description 1",
        },
        {
          icon: "mdi-calculator-variant-outline",
          title: "Companies' financials",
          description: "Description 2",
        },
        {
          icon: "mdi-chart-line",
          title: "Technical indicators",
          description: "Description 3",
        },
        {
          icon: "mdi-briefcase-search-outline",
          title: "Portfolio risks",
          description: "Description 4",
        },
        {
          icon: "mdi-email-alert-outline",
          title: "Alerts",
          description: "Description 5",
        },
        {
          icon: "mdi-robot-excited-outline",
          title: "AI analysis",
          description: "Description 6",
        },
      ],
    };
  },
};
</script>
