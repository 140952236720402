<template>
  <v-sheet>
    <div class="tradingview-widget-container">
      <div ref="tradingviewFinancials"></div>
      <div class="tradingview-widget-copyright">
        <a
          href="https://www.tradingview.com/symbols/NASDAQ-AAPL/financials-overview/"
          rel="noopener"
          target="_blank"
        >
          <span class="blue-text">{{ tickerInfo }} fundamentals</span>
        </a>
        by TradingView
      </div>
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: "TradingViewFinancials",
  data() {},
  mounted() {
    this.createTradingViewFinancials();
  },

  computed: {
    tickerInfo() {
      return this.exchange + ":" + this.shareExchange;
    },
  },

  watch: {
    tickerInfo() {
      // Remove the existing widget
      this.$refs.tradingviewFinancials.innerHTML = "";

      // Create a new widget with the updated ticker
      this.createTradingViewFinancials();
    },
  },

  props: {
    ticker: Object,
    shareExchange: Object,
  },
  methods: {
    createTradingViewFinancials() {
      const script = document.createElement("script");
      script.src =
        "https://s3.tradingview.com/external-embedding/embed-widget-financials.js";
      script.async = true;
      script.innerHTML = JSON.stringify({
        colorTheme: "light",
        isTransparent: false,
        largeChartUrl: "",
        displayMode: "regular",
        width: 650,
        height: 400,
        symbol: this.ticker,
        // symbol: this.tickerInfo,
        locale: "en",
      });
      this.$refs.tradingviewFinancials.appendChild(script);
    },
  },
};
</script>
