<template>
  <div class="text-center">
    <v-dialog v-model="localDialog" width="800">
      <v-card>
        <v-autocomplete
          v-model="selectedItem"
          variant="outlined"
          label="Search for a company"
          :items="portfolioDataStore.sharesSearch"
          clearable
          class="pa-4 ma-0"
          item-title="search"
          item-value="sharesSearch"
          @update:search="onSearchInput($event, selectedItem)"
        ></v-autocomplete>
        <v-card class="mx-auto" max-width="800" width="800">
          <v-card-text class="content-wrapper">
            <!-- Logo Avatar & Company Name -->
            <div class="header-section">
              <v-avatar :image="selectedShare.logo" size="64"></v-avatar>
              <div class="company-info">
                <p class="text-h4 text--primary company-name">
                  {{ selectedShare.company_name || " " }}
                </p>
                <div class="ticker">{{ selectedShare.ticker }}</div>
              </div>
            </div>

            <!-- Additional Info: Country, Industry, and Sector -->
            <div class="details-section">
              <div class="detail-block">
                <div class="detail-title">Country</div>
                <p class="detail-content country">
                  {{ selectedShare.country }}
                </p>
              </div>
              <div class="detail-block">
                <div class="detail-title">Industry</div>
                <p class="detail-content industry">
                  {{ selectedShare.industry }}
                </p>
              </div>
              <div class="detail-block">
                <div class="detail-title">Sector</div>
                <p class="detail-content sector">{{ selectedShare.sector }}</p>
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click.stop="addShareToPortfolio"
              variant="text"
              color="secondary"
            >
              add share to portfolio
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import { useUserDataStore } from "@/store/userDataStore.js";
import { usePortfolioDataStore } from "@/store/portfolioDataStore.js";
import { addShareToPortfolioAPI } from "@/api/yoda_api";
import { useRoute } from "vue-router";

export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const userDataStore = useUserDataStore();
    const portfolioDataStore = usePortfolioDataStore();
    const selectedItem = ref(null);
    const route = useRoute();
    const portfolioId = route.params.portfolioId;
    const localDialog = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit("update:modelValue", value);
      },
    });

    // Function to trigger when dialog is opened
    const onDialogOpen = async () => {
      // <-- Declared as async
      console.log("Dialog opened!");
      try {
        await portfolioDataStore.getSharesSearch();
      } catch (error) {
        console.error(`Error fetching portfolio`, error);
        return null;
      }
    };

    // Watch for changes in the localDialog state
    watch(localDialog, (newValue) => {
      if (newValue) {
        onDialogOpen();
      }
    });

    const selectedShare = computed(() => {
      // Ensure selectedItem.value is valid before finding a match
      console.log("selected :", selectedItem.value);
      if (!selectedItem.value) {
        return {};
      }

      return (
        portfolioDataStore.sharesSearch.find(
          (share) => share.search === selectedItem.value
        ) || {}
      );
    });

    const onSearchInput = async (value, selectedItem) => {
      // If the value is the same as selectedItem, don't proceed
      if (value === selectedItem) {
        return;
      }

      // If the value is not valid, don't proceed
      console.log("fire search share", value, selectedItem);
      if (!value || value.trim() === "") {
        return;
      }
      try {
        await portfolioDataStore.getSharesSearch(value);
      } catch (error) {
        console.error(`Error fetching portfolio`, error);
        return null;
      }
    };

    const addShareToPortfolio = async () => {
      console.log("fire adding share", selectedShare);

      try {
        await addShareToPortfolioAPI(selectedShare.value.id, portfolioId);
        await refreshPortfolioData(portfolioId);
      } catch (error) {
        console.error(`Error fetching portfolio`, error);
        return null;
      }
    };

    const refreshPortfolioData = async () => {
      try {
        await portfolioDataStore.getPortfolioHoldings(
          userDataStore.idToken,
          portfolioId
        );
      } catch (error) {
        console.error(`Error fetching portfolio`, error);
        return null;
      }
    };

    return {
      userDataStore,
      portfolioDataStore,
      localDialog,
      onSearchInput,
      selectedItem,
      selectedShare,
      addShareToPortfolio,
      refreshPortfolioData,
      portfolioId,
    };
  },
};
</script>

<style>
.content-wrapper {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;

  .header-section {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .company-info {
      margin-left: 20px;
      .ticker {
        color: #aaa;
        font-size: 14px;
      }
    }
  }

  .details-section {
    display: flex;
    justify-content: space-between;

    .detail-block {
      flex: 1;
      background-color: #fff;
      padding: 10px;
      margin: 5px;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

      .detail-title {
        color: #aaa;
        font-weight: bold;
      }

      .detail-content {
        margin-top: 8px;
      }
    }
  }
}
</style>
