<template>
  <v-sheet
    class="pb-6 pt-3"
    rounded="lg"
    max-width="100%"
    width="100%"
    style="box-shadow: 0 0 10px rgba(0, 0, 0, 0.05) back"
  >
    <DataTable
      :value="portfolioDataStore.selectedPortfolioShare.share.transactions"
      :class="`p-datatable-sm`"
      stripedRows
      removableSort
      scrollable
      scrollHeight="400px"
      tableStyle="min-width: 50rem"
    >
      <Column
        field="transaction_date"
        header="Date"
        sortable
        :headerStyle="captionHeaderStyle"
        :bodyStyle="captionBodyStyle"
      >
      </Column>
      <Column
        :headerStyle="captionHeaderStyle"
        :bodyStyle="captionBodyStyle"
        field="price"
        header="Price"
        sortable
      ></Column>
      <Column
        :headerStyle="captionHeaderStyle"
        :bodyStyle="captionBodyStyle"
        field="quantity"
        header="Quantity"
        sortable
      ></Column>
      <Column
        :headerStyle="captionHeaderStyle"
        :bodyStyle="captionBodyStyle"
        field="total_cost"
        header="Total cost"
        sortable
      ></Column>
      <Column
        :headerStyle="captionHeaderStyle"
        :bodyStyle="captionBodyStyle"
        field="comment"
        header="Comment"
      ></Column>
      <Column
        :headerStyle="captionHeaderStyle"
        :bodyStyle="captionBodyStyle"
        field=""
        header=""
      >
        <template #body="{ data }">
          <v-btn
            @click="deleteTransaction(data)"
            density="compact"
            icon="mdi-delete-forever"
            variant="plain"
          ></v-btn>
        </template>
      </Column>
    </DataTable>
  </v-sheet>
</template>

<script>
import { usePortfolioDataStore } from "@/store/portfolioDataStore.js";
import { useUserDataStore } from "@/store/userDataStore.js";
import { deletePortfolioTransactionAPI } from "@/api/yoda_api";
import { useRoute } from "vue-router";

export default {
  data: () => ({
    items: [{ title: "Edit" }, { title: "Close" }, { title: "Delete" }],
    captionHeaderStyle: {
      "font-family": "Sora",
      "font-size": "0.75rem",
      "font-weight": "500",
      "line-height": "1.25",
      "letter-spacing": "0.03333em",
      "text-transform": "uppercase",
      "padding-top": "15px",
      "padding-bottom": "15px",
      background: "#f7f7f7",
    },
    captionBodyStyle: {
      "font-family": "Sora",
      "font-size": "0.75rem",
      "font-weight": "500",
      "line-height": "1.25",
      "letter-spacing": "0.03333em",
      cursor: "pointer",
    },
  }),
  setup() {
    const route = useRoute();
    const portfolioDataStore = usePortfolioDataStore();
    const userDataStore = useUserDataStore();
    const portfolioId = route.params.portfolioId;
    return {
      portfolioDataStore,
      userDataStore,
      portfolioId,
    };
  },
  methods: {
    async deleteTransaction(transaction) {
      try {
        const response = await deletePortfolioTransactionAPI(
          this.userDataStore.idToken,
          transaction.id
        );
        console.log(response);
        try {
          await this.portfolioDataStore.getPortfolioHoldings(
            this.userDataStore.idToken,
            this.portfolioId
          );

          // Find the updated share from portfolioHoldings based on the id of selectedPortfolioShare
          const updatedShare =
            this.portfolioDataStore.portfolioHoldings.data.find(
              (share) =>
                share.id ===
                this.portfolioDataStore.selectedPortfolioShare.share.id
            );

          // Update selectedPortfolioShare with the found share
          if (updatedShare) {
            this.portfolioDataStore.selectedPortfolioShare.share = updatedShare;
          }
        } catch (error) {
          console.error(`Error fetching portfolio`, error);
          return null;
        }
      } catch (error) {
        console.error("Error deleting transaction:", error);
      }
    },
  },
};
</script>

<style>
.elevation-3 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
</style>
