<template>
  <v-navigation-drawer v-model="drawer" :rail="rail" permanent>
    <!-- @click="rail = false" -->
    <v-sheet class="d-flex flex-column fill-height">
      <v-sheet
        height="120"
        class="d-flex justify-center align-center bg-surface-variant pb-3"
      >
        <div class="logo">
          <img src="@/assets/logo.png" alt="Logo" />
          <!-- <h2 class="app-title">Application Name</h2> -->
        </div>
      </v-sheet>
      <v-divider class="border-opacity-100" color="grey"></v-divider>
      <v-sheet class="flex-grow-1 bg-surface-variant my">
        <v-list v-model:opened="open" density="default" class="mt-7">
          <v-list-item
            prepend-icon="mdi-view-dashboard"
            active-color="secondary"
            variant="plain"
            title="Dashboard"
            value="dashboard"
            @click="$router.push('/user_home/dashboard')"
            :class="{ active: $route.path === '/user_home/dashboard' }"
          ></v-list-item>

          <v-list-group value="My portfolios">
            <template v-slot:activator="{ props }">
              <v-list-item
                v-bind="props"
                prepend-icon="mdi-shopping-outline"
                title="My portfolios"
              ></v-list-item>
            </template>

            <v-list-item
              v-for="(portfolio, i) in userDataStore.userData.portfolios"
              :key="i"
              :value="portfolio.name"
              :title="portfolio.name"
              @click="$router.push(`/user_home/portfolio/${portfolio.id}`)"
              :class="{
                active: $route.path === `/user_home/portfolio/${portfolio.id}`,
              }"
            >
            </v-list-item>
            <!-- Create Portfolio Item -->
            <v-list-item
              prepend-icon="mdi-plus"
              active-color="secondary"
              @click="createPortfolioDialog = true"
            >
              <v-list-item-content>
                <v-list-item-title>new </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <!-- <v-list-item
            prepend-icon="mdi-chart-arc"
            active-color="secondary"
            variant="plain"
            title="Analytics!"
            value="analytics"
            @click="$router.push('/user_home/analytics')"
            :class="{ active: $route.path === '/user_home/analytics' }"
          ></v-list-item> -->
        </v-list>
      </v-sheet>
      <v-divider class="border-opacity-100" color="grey"></v-divider>
      <v-sheet class="align-end bg-surface-variant">
        <v-list>
          <div style="flex-grow: 2"></div>
          <!-- <v-list-item
            prepend-icon="mdi-cog"
            variant="plain"
            title="Settings"
            value="settings"
            @click="dialog = true"
          ></v-list-item> -->

          <v-list-item
            prepend-icon="mdi-exit-to-app"
            variant="plain"
            title="Logout"
            value="logout"
            @click="logout"
          ></v-list-item>

          <v-list-item nav>
            <template v-slot:append>
              <v-btn
                variant="text"
                icon="mdi-chevron-left"
                @click.stop="rail = !rail"
              ></v-btn>
            </template>
          </v-list-item>
        </v-list>
      </v-sheet>
    </v-sheet>
  </v-navigation-drawer>

  <v-dialog v-model="dialog" width="auto">
    <SettingsContent />
  </v-dialog>

  <v-dialog v-model="createPortfolioDialog" width="500">
    <!-- Here, you can either use an inline content or a new component like CreatePortfolioContent -->
    <!-- Inline content example below: -->
    <v-card>
      <v-card-title>Create a new Portfolio</v-card-title>
      <v-card-text>
        <!-- Form to get the portfolio details -->
        <v-text-field
          label="Portfolio Name"
          v-model="newPortfolio.name"
        ></v-text-field>
        <!-- Add more fields as needed -->
      </v-card-text>
      <v-card-actions>
        <v-btn @click="createPortfolio">Create</v-btn>
        <v-btn text @click="createPortfolioDialog = false">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from "vue";
import "@/styles.css";
import firebase from "@/firebase";
import SettingsContent from "@/components/settings/SettingsContent.vue";
import { addNewPortfolioAPI } from "@/api/yoda_api";
import { useUserDataStore } from "@/store/userDataStore.js";
import { usePortfolioDataStore } from "@/store/portfolioDataStore.js";
import { useRouter } from "vue-router";

export default {
  name: "NavigationPanel",
  open: ["Actions"],
  components: {
    SettingsContent,
  },

  props: {
    currentTab: {
      type: Number,
    },
  },

  setup() {
    const router = useRouter();
    const userDataStore = useUserDataStore();
    const open = ref(["My portfolios"]);
    const showSettingsPopup = ref(false);
    const dialog = ref(false);
    const drawer = ref(true);
    const rail = ref(false);
    const userData = ref(null);
    const portfolioDataStore = usePortfolioDataStore();
    const createPortfolioDialog = ref(false);
    const newPortfolio = ref({ name: "" });

    function selectTab(tab) {
      this.$emit("update-tab", tab);
    }

    async function logout() {
      try {
        await firebase.auth().signOut();
        userDataStore.logout();
        portfolioDataStore.logout();
        router.push("/");
      } catch (error) {
        console.error("Logout error:", error);
      }
    }

    function openSettingsPopup() {
      showSettingsPopup.value = true;
    }

    async function createPortfolio() {
      try {
        await addNewPortfolioAPI(newPortfolio.value.name);
        await userDataStore.fetchUserData(userDataStore.idToken);
      } catch (error) {
        console.error(`Error fetching portfolio`, error);
        return null;
      }

      createPortfolioDialog.value = false;
    }

    return {
      showSettingsPopup,
      dialog,
      drawer,
      rail,
      open,
      userData,
      userDataStore,
      selectTab,
      logout,
      openSettingsPopup,
      portfolioDataStore,
      createPortfolioDialog,
      newPortfolio,
      createPortfolio,
      addNewPortfolioAPI,
    };
  },
};
</script>

<style scoped>
.sidebar {
  /* padding: 1rem; */
  background-color: #fff;
}

.nav-item:hover {
  color: black;
}

.nav-item.active {
  background-color: #fcb43a;
  color: #fff;
}

.settings-btn:hover,
.logout-btn:hover {
  border: 1px solid var(--light-text-color);
}

.logo-transition {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.logo img {
  height: auto; /* Maintain the aspect ratio */
  padding-top: 15px; /* Add top padding to the logo image */
}
</style>
