<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-textarea
          label="Your investment strategy for AI insights"
          v-model="userInput"
          outlined
          no-resize
          rows="5"
        ></v-textarea>

        <v-btn @click="askAI" color="primary"
          >Ask AI for analysis (daily request)</v-btn
        >

        <!-- Loader while waiting for response -->
        <div v-if="isLoading" class="loader-wrapper">
          <v-progress-circular
            :width="10"
            indeterminate
            color="secondary"
          ></v-progress-circular>
        </div>

        <v-divider class="my-4"></v-divider>

        <v-textarea
          label="AI insights"
          :model-value="portfolioDataStore.AIresponse"
          outlined
          no-resize
          rows="15"
          readonly
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref } from "vue";
import { usePortfolioDataStore } from "@/store/portfolioDataStore.js";

const userInput = ref("");
const isLoading = ref(false);
const portfolioDataStore = usePortfolioDataStore();

const askAI = async () => {
  isLoading.value = true;

  const clean = processSharesForAnalysis(
    portfolioDataStore.portfolioHoldings.data
  );
  console.log("clean data ", clean);
  const stringifiedData = JSON.stringify(userInput.value);
  await portfolioDataStore.postAiRequest(
    portfolioDataStore.selectedPortfolioId,
    stringifiedData
  );

  // Assuming the AIresponse now has the data, you can clean it up her
  isLoading.value = false;
};

function processSharesForAnalysis(shares) {
  return shares.map((share) => {
    let shareInfo = {
      ticker: share.ticker.value,
      companyName: share.company_name.value,
      price: share.price.value,
      change: share.change.value,
      totalInvested: share.totalCost.value,
      transactions: share.transactions ? share.transactions.length : 0, // number of transactions
    };

    let description = `Ticker: ${shareInfo.ticker}, Company: ${shareInfo.companyName}, Price: ${shareInfo.price}, Change: ${shareInfo.change}`;

    if (shareInfo.transactions > 0) {
      description += `, Transactions: ${shareInfo.transactions}`;
    }

    return description;
  });
}
</script>

<style scoped>
.loader-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(
    255,
    255,
    255,
    0.8
  ); /* Optional: Semi-transparent white background */
}
</style>
