<template>
  <div class="portfolio-view">
    <CardDashboard />
    <MainRoundedForm>
      <template #title>
        <PortfolioTitle class="title-margin" />
      </template>

      <template #content>
        <PortfolioContent class="content-margin" />
      </template>
    </MainRoundedForm>
  </div>
</template>

<script setup>
import MainRoundedForm from "@/components/reusable/MainRoundedForm.vue";
import PortfolioContent from "@/components/portfolio/PortfolioContent.vue";
import CardDashboard from "@/components/portfolio/Cards/CardDashboard.vue";
import { useRoute } from "vue-router";
import { useUserDataStore } from "@/store/userDataStore.js";
import { usePortfolioDataStore } from "@/store/portfolioDataStore.js";
import { watchEffect } from "vue";

const route = useRoute();
const portfolioDataStore = usePortfolioDataStore();
const userDataStore = useUserDataStore();

const refreshPortfolioData = async (portfolioId) => {
  await portfolioDataStore.getPortfolioHoldings(
    userDataStore.idToken,
    portfolioId
  );
};

watchEffect(() => {
  const id = route.params.portfolioId;
  console.log("fire portfolio refresh");
  portfolioDataStore.setSelectedPortfolioId(id);
  refreshPortfolioData(id);
});

// const portfolioDataStore = usePortfolioDataStore();
</script>

<style></style>
