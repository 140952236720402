<template>
  <v-row>
    <v-col cols="3">
      <v-btn variant="text" density="compact"> {{ totalInvestedLabel }}</v-btn>
    </v-col>
    <v-col cols="3">
      <v-btn variant="text" density="compact">
        {{ totalProfitLossLabel }}</v-btn
      >
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <DataTable
        resizableColumns
        ref="dataTable"
        columnResizeMode="fit"
        class="custom-datatable p-datatable-sm"
        selectionMode="single"
        dataKey="id"
        tableClass="editable-cells-table"
        :value="portfolioDataStore.portfolioHoldings.data"
        @row-click="toggleHoldingDialog($event)"
        tableStyle="min-width: calc(50rem - 5px * 5);"
      >
        <ColumnGroup type="header">
          <Row>
            <Column
              v-for="col of columns"
              :key="col.field"
              :headerStyle="captionHeaderStyle"
              :header="col.header"
              :style="`width: ${col.width}; max-width: ${col.width};`"
            />
          </Row>
        </ColumnGroup>

        <Column
          v-for="col of columns"
          :key="col.field"
          :field="col.field"
          :header="col.header"
          :headerStyle="captionHeaderStyle"
          :bodyStyle="captionBodyStyle"
          :sortable="col.sortable ? true : false"
          :style="`width: ${col.width}; max-width: ${col.width};`"
        >
          <template v-if="col.field === 'logo'" #body="{ data }">
            <v-avatar
              color="darkbackround"
              class="pa-2 ma-0"
              rounded="1"
              :image="data.logo"
              size="35"
            ></v-avatar>
          </template>

          <template v-else #body="{ data }">
            <template v-if="shouldShowBadge(data[col.field])">
              <v-badge
                floating
                class="custom-badge"
                :content="badgeContent(data[col.field])"
                :color="badgeColor(data[col.field].level_status)"
              >
                {{ col.prefix ? col.prefix : "" }}
                <template v-if="col.field !== 'ticker'">
                  {{ data[col.field].value }}
                </template>
                <template v-else>
                  <a
                    @click.prevent="triggerHoldingDialog(data, $event)"
                    :href="`/user_home/details/${data[col.field].value}`"
                    class="ticker-link"
                  >
                    {{ data[col.field].value }}
                  </a>
                </template>
              </v-badge>
            </template>
            <template v-else-if="col.field === 'profitOrLoss'">
              {{ col.prefix ? col.prefix : "" }}
              {{
                parseFloat(data[col.field].value).toLocaleString("en-US", {
                  maximumFractionDigits: 0,
                })
              }}
            </template>

            <template v-else-if="isObject(data[col.field])">
              {{ col.prefix ? col.prefix : "" }} {{ data[col.field].value }}
            </template>

            <template v-else>
              {{ col.prefix ? col.prefix : "" }} {{ data[col.field] }}
            </template>
          </template>
        </Column>
      </DataTable>
    </v-col>
  </v-row>
</template>

<script>
import { useUserDataStore } from "@/store/userDataStore.js";
import { usePortfolioDataStore } from "@/store/portfolioDataStore.js";

export default {
  props: {
    columns: Array,
  },
  setup() {
    const userDataStore = useUserDataStore();
    const portfolioDataStore = usePortfolioDataStore();
    return {
      userDataStore,
      portfolioDataStore,
    };
  },
  data() {
    return {
      showDetails: false,
      selectedPortfolioShare: {},
      isNewShareClicked: false,
      total_result: "",
      totalProfitLoss: "",
      totalInvested: "",
      total_invested: "",
      captionHeaderStyle: {
        "font-family": "Sora",
        "font-size": "0.75rem",
        "font-weight": "400",
        "line-height": "1.25",
        "letter-spacing": "0.02em",
        "text-transform": "uppercase",
        "padding-top": "15px",
        "padding-bottom": "15px",
        "word-wrap": "break-word",
        background: "#F8F8F8",

        "text-align": "center",
      },
      captionHeaderTotalStyle: {
        "font-family": "Sora",
        "font-size": "0.75rem",
        "font-weight": "500",
        "line-height": "0.25",
        "letter-spacing": "0.01em",
        "padding-bottom": "10px",
        "text-transform": "uppercase",
      },
      captionBodyStyle: {
        "font-family": "Sora",
        "font-size": "0.75rem",
        "font-weight": "500",
        "line-height": "1.25",
        "letter-spacing": "0.03333em",
        cursor: "pointer",

        selectedColumns: null,
        columns: null,
        products: null,
      },
    };
  },
  async created() {
    console.log("Portfolio data is being refreshed at creation");
    // const portfolioId = this.$route.params.portfolioId;
    // this.refreshPortfolioData(portfolioId);
    this.selectedColumns = this.columns;
  },
  computed: {
    totalProfitLossLabel() {
      return `Total Profit/Loss: ${parseFloat(
        this.portfolioDataStore.portfolioHoldings.totalPortfolioProfitOrLoss
      ).toLocaleString("en-US", { maximumFractionDigits: 0 })}`;
    },
    totalInvestedLabel() {
      return `Total Invested: ${parseFloat(
        this.portfolioDataStore.portfolioHoldings.totalPortfolioCost
      ).toLocaleString("en-US", { maximumFractionDigits: 0 })}`;
    },
    totalColumnsMinusThree() {
      return this.columns.length - 4;
    },
    // ... other computed properties
  },
  methods: {
    isObject(item) {
      return typeof item === "object";
    },
    shouldShowBadge(item) {
      return (
        this.isObject(item) &&
        item.tracker &&
        "level_status" in item &&
        item.level_status !== null
      );
    },

    badgeColor(level_status) {
      const colors = ["red", "yellow", "green"];
      return colors[level_status] || "default"; // Added 'default' as a fallback color
    },
    badgeContent(item) {
      return item.next_level;
    },
    onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col));
    },
    transacionsAddedForNewShare() {
      this.refreshPortfolioData();
      this.showDetails = false;
    },

    async refreshPortfolioData(portfolioId) {
      await this.portfolioDataStore.getPortfolioHoldings(
        this.userDataStore.idToken,
        portfolioId
      );
    },

    triggerHoldingDialog(event) {
      this.toggleHoldingDialog({ data: event });
      // You might want to navigate manually if needed

      this.$router.push(
        `/user_home/details/${event.exchange}/${event.ticker.value}`
      );
    },

    toggleHoldingDialog(event) {
      this.portfolioDataStore.setSelectedPortfolioShare(event.data);
      this.$emit("holding-row-clicked");
    },
  },
};
</script>

<style>
.custom-badge .v-badge__badge {
  font-size: 9px; /* Adjust as needed */
}
.ticker-link {
  color: #424242; /* Replace #yourColor with the color you want */
  font-weight: bold;
}
.ticker-link:hover {
  color: #fcb43a; /* Replace #hoverColor with the color you want on hover */
}
</style>
