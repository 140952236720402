<template>
  <div>
    <PortfolioTabs @tab-changed="handleTabChange" />
    <!-- <PortfolioChips
      :headers="currentColumns"
      @chip-toggled="handleChipToggle"
    /> -->
    <AIInsightsComponent v-if="isAIInsightsTab" />
    <PortfolioTable
      v-else
      :shares="shares"
      :columns="visibleColumns"
      @holding-row-clicked="openDialog"
    />
  </div>
  <div>
    <TransactionsDialog
      :dialog="dialogState"
      @close-dialog="dialogState = false"
    />
  </div>
  <div>
    <TrackersDialog :dialog="dialogState" @close-dialog="dialogState = false" />
  </div>
</template>

<script>
import PortfolioTabs from "@/components/portfolio/PortfolioTabs.vue";
import PortfolioTable from "@/components/portfolio/PortfolioTable.vue";
import TransactionsDialog from "@/components/portfolio/TransactionsDialog/TransactionsDialog.vue";
import AIInsightsComponent from "@/components/portfolio/AI/AiInsights.vue";
import { usePortfolioDataStore } from "@/store/portfolioDataStore.js";
// import PortfolioChips from "@/components/portfolio/Chips/PortfolioChips.vue";

export default {
  components: {
    // PortfolioChips,
    PortfolioTabs,
    PortfolioTable,
    TransactionsDialog,
    AIInsightsComponent,
  },
  setup() {
    const portfolioDataStore = usePortfolioDataStore();
    return {
      portfolioDataStore,
    };
  },

  data() {
    return {
      shares: [], // Placeholder for your shares data
      visibleColumns: [],

      activeTab: "Holdings", // Default tab
      dialogState: false,
    };
  },

  computed: {
    allColumns() {
      let myTrackersColumns = [];

      // Static columns for "My Trackers"
      const staticColumns = [
        {
          header: "",
          field: "logo",
          editor: false,
          width: "5%",
          visible: true,
        },
        { header: "Ticker", field: "ticker", width: "10%", visible: true },
        // ... any other static columns ...
      ];
      const dynamicColumns =
        this.portfolioDataStore.portfolioHoldings.myTrackerColumns || [];
      myTrackersColumns = myTrackersColumns.concat(
        staticColumns,
        dynamicColumns
      );

      return {
        Holdings: [
          {
            header: "",
            field: "logo",
            editor: false,
            width: "5%",
            visible: true,
          },
          { header: "Ticker", field: "ticker", width: "5%", visible: true },
          {
            header: "Name",
            field: "company_name",
            width: "10%",
            visible: true,
          },
          { header: "Price", field: "price", width: "10%", visible: true },
          {
            header: "Position",
            field: "totalQuantity",
            width: "10%",
            visible: true,
          },
          {
            header: "Invested",
            field: "totalCost",
            width: "10%",
            prefix: "$",
            visible: true,
          },
          {
            header: "Current result",
            field: "profitOrLoss",
            width: "10%",
            prefix: "$",
            visible: true,
          },
        ],
        Financials: [
          {
            header: "",
            field: "logo",
            editor: false,
            width: "5%",
            visible: true,
          },
          { header: "Ticker", field: "ticker", width: "5%", visible: true },
          // {
          //   header: "Name",
          //   field: "company_name",
          //   width: "10%",
          //   visible: true,
          //   sortable: true,
          // },
          // { header: "Price", field: "price", width: "10%", visible: true },
          { header: "EBITDA", field: "EBITDA", width: "10%", visible: true },
          {
            header: "Revenue growth",
            field: "Revenue growth",
            width: "10%",
            visible: true,
          },
          {
            header: "Dividend yield, %",
            field: "Dividend yield",
            width: "10%",
            visible: true,
          },
          {
            header: "Analysts target, %",
            field: "Analysts target, %",
            width: "10%",
            visible: true,
          },

          {
            header: "Fair Value, %",
            field: "Fair Value, %",
            width: "10%",
            visible: true,
          },

          // ... other columns specific to the Financials tab
        ],
        Technical: [
          {
            header: "",
            field: "logo",
            editor: false,
            width: "5%",
            visible: true,
          },
          { header: "Ticker", field: "ticker", width: "5%", visible: true },
          // {
          //   header: "Name",
          //   field: "company_name",
          //   width: "10%",
          //   visible: true,
          // },
          // { header: "Price", field: "price", width: "10%", visible: true },
          {
            header: "MA (200d), %",
            field: "MA (200d), %",
            width: "10%",
            visible: true,
          },
          {
            header: "RSI (14d)",
            field: "RSI (14d)",
            width: "10%",
            visible: true,
          },
          {
            header: "52 Weeks High, %",
            field: "52 Weeks High, %",
            width: "10%",
            visible: true,
          },
          // ... other columns specific to the Risk tab
        ],
        Risk: [
          {
            header: "",
            field: "logo",
            editor: false,
            width: "5%",
            visible: true,
          },
          { header: "Ticker", field: "ticker", width: "5%", visible: true },
          // {
          //   header: "Name",
          //   field: "company_name",
          //   width: "10%",
          //   visible: true,
          // },
          // { header: "Price", field: "price", width: "10%", visible: true },
          {
            header: "Beta (2 Year)",
            field: "Beta (2 Year)",
            width: "10%",
            visible: true,
          },
          {
            header: "Invested",
            field: "totalCost",
            width: "10%",
            prefix: "$",
            visible: true,
          },
          // ... other columns specific to the Risk tab
        ],
        "My Trackers": myTrackersColumns,
      };
    },

    currentColumns() {
      return this.allColumns[this.activeTab];
    },

    isAIInsightsTab() {
      return this.activeTab === "AI insights"; // Make sure the name matches exactly with your tab name.
    },
  },
  methods: {
    openDialog() {
      this.dialogState = true;
    },
    handleTabChange(tabName) {
      this.activeTab = tabName;
      if (this.activeTab !== "AI insights") {
        this.setVisibleColumns();
      }
    },
    handleChipToggle(toggledColumn) {
      const column = this.allColumns[this.activeTab].find(
        (col) => col.field === toggledColumn.field
      );
      if (column) {
        column.visible = !column.visible;
      }
      this.setVisibleColumns();
    },
    setVisibleColumns() {
      this.visibleColumns = this.currentColumns.filter(
        (column) => column.visible
      );
    },
  },
  created() {
    this.visibleColumns = this.currentColumns.filter(
      (column) => column.visible
    );
  },
};
</script>
